import { downloadPlatonFile, prettyBytes, stringToColour } from "@Platon/core/helpers"
import { API_BASE_URL, IS_DEV, UPLOAD_ENDPOINT, PRIVATE_UPLOAD_ENDPOINT } from "@Platon/const"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import axios from "axios"
import ToastMixin from "@Platon/mixins/ToastMixin"
import moment from "moment"
import ModalMixin from "@Platon/mixins/ModalMixin"
import { limitFileSizeErrorToast, sizeKbToByte } from "@Platon/components/form/controls/UploadMultipleLimitsMixin"
import { base64ToFile } from "@Platon/core/helpers/FileHelpers"

const CancelToken = axios.CancelToken

export default {
	mixins: [InputControlMixin, ToastMixin, ModalMixin],

	props: {
		remote: {}
	},

	data() {
		return {
			/** @type File */
			selectedFile: null,
			isUploading: false,
			isUploadError: false,
			/** @type ?PlatonFile */
			remoteFile: this.remote,
			cancel: null,
			uploadProgress: 0
		}
	},

	methods: {
		/**
		 * @param {File} file
		 */
		async onFileSelected(file) {
			if (!file) {
				if (IS_DEV) console.log("File not selected")
				return
			}

			const isBiggerThanMaxSize = file.size > sizeKbToByte(this.item.maxSize)
			const isImage = file.type.includes("image")

			if (isBiggerThanMaxSize && isImage) {
				file = await this.compress(file, 1200, 1000, "image/jpeg", 1)
			} else if (isBiggerThanMaxSize && !isImage) {
				limitFileSizeErrorToast(this.item.maxSize)
				return
			}

			this.selectedFile = file
			return this.uploadFile()
		},

		async uploadFile() {
			this.isUploading = true
			this.isUploadError = false

			this.cancel = CancelToken.source()

			let imageResponse

			const formData = new FormData()
			formData.append("form_element_id", this.$attrs["element-id"])
			formData.append("form_element_project_id", this.item.projectId)
			formData.append("file", this.selectedFile)

			try {
				const response = await this.$http({
					baseURL: API_BASE_URL,
					method: "post",
					url: this.$route.path.startsWith("/public") ? PRIVATE_UPLOAD_ENDPOINT : UPLOAD_ENDPOINT,
					data: formData,
					cancelToken: this.cancel.token,
					onUploadProgress: (progressEvent) => {
						this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					}
				})

				this.updateLocal(response.data.id)
				this.remoteFile = response.data
				this.previewUploaded = true
				imageResponse = response.data
			} catch (error) {
				this.isUploadError = true

				if (!axios.isCancel(error)) {
					this.errorToast(error.message)
				}
			} finally {
				this.cancel = null
				this.isUploading = false
			}

			return imageResponse
		},

		displayInfo(label, file) {
			if (IS_DEV) console.log(`${label} - ${prettyBytes(file.size)}`)
		},

		calculateSize(img, maxWidth, maxHeight) {
			let width = img.width
			let height = img.height

			// calculate the width and height, constraining the proportions
			if (width > height) {
				if (width > maxWidth) {
					height = Math.round((height * maxWidth) / width)
					width = maxWidth
				}
			} else {
				if (height > maxHeight) {
					width = Math.round((width * maxHeight) / height)
					height = maxHeight
				}
			}
			return [width, height]
		},

		compress(file, MAX_WIDTH, MAX_HEIGHT, MIME_TYPE, QUALITY) {
			const that = this
			return new Promise((resolve, reject) => {
				const img = new Image()

				img.onload = function () {
					URL.revokeObjectURL(this.src)
					const [newWidth, newHeight] = that.calculateSize(img, MAX_WIDTH, MAX_HEIGHT)
					const canvas = document.createElement("canvas")
					canvas.width = newWidth
					canvas.height = newHeight
					const ctx = canvas.getContext("2d")
					ctx.drawImage(img, 0, 0, newWidth, newHeight)
					canvas.toBlob(
						(blob) => {
							// Handle the compressed image. es. upload or save in local state
							that.displayInfo("Original file", file)
							that.displayInfo("Compressed file", blob)
							resolve(blob) // Resolve the Promise with the compressed blob.
						},
						MIME_TYPE,
						QUALITY
					)
				}

				img.src = URL.createObjectURL(file)
			})
		},

		cancelUpload() {
			if (this.cancel) {
				this.cancel.cancel("User requested to cancel")
				this.cancel = null
			}

			this.$emit("cancel")
		},

		deleteUploadedFile() {
			this.showConfirmDialog(
				this.$l("platon.attention", "Диққат"),
				this.$l("platon.confirm_file_deletion", "Файлни ўчиришни хоҳлайсизми?")
			).then(() => {
				this.updateLocal(null)
				this.selectedFile = null
				this.remoteFile = null
				this.$emit("deleted")

				// alert('Deleted locally!')
			})
		},

		downloadRemoteFile() {
			downloadPlatonFile(this.remoteFile.id)
		}
	},

	computed: {
		hasUploadedFile() {
			if (Array.isArray(this.local)) return this.local.length > 0

			return !!this.local
		},

		canDownload() {
			return !!this.remoteFile
		},

		fileName() {
			if (this.selectedFile) return this.selectedFile.name

			if (this.remoteFile) {
				return this.remoteFile.name
			}

			return this.$l("platon.select_file", "Файлни танланг")
		},

		fileDescription() {
			if (this.remoteFile) {
				let date = this.remoteFile.createdAt
					? moment(this.remoteFile.createdAt, "DD.MM.YYYY HH:mm:ss")
					: moment()
				return `${date.format("DD.MM.YYYY")}, ${prettyBytes(this.remoteFile.size)}`
			} else if (this.selectedFile) {
				let date = moment()
				return `${date.format("DD.MM.YYYY")}, ${prettyBytes(this.selectedFile.size)}`
			}
		},

		fileExtension() {
			if (this.remoteFile) {
				return this.remoteFile.extension
			} else if (this.selectedFile) {
				return this.selectedFile.name.split(".").reverse()[0]
			}

			return "file"
		},
		allowedExtensions() {
			// pdf,doc,xls ==> .pdf,.doc,.xls
			let ext = this.item.allowedExtensions || []

			return ext
				.map((x) => {
					if (x === "*") return "*"

					return "." + x
				})
				.join(",")
		},

		fileIconColor() {
			return stringToColour(this.fileExtension)
		}
	}
}
