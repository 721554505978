import { VueEIMZO } from "vue-eimzo"
import HujjatUzEIMZOClient from "@Platon/core/eimzo/HujjatUzEIMZOProvider"

export default {
	computed: {
		isDirectClient() {
			return process.env.VUE_APP_EIMZO_CLIENT === "direct"
		}
	},
	methods: {
		getClient() {
			if (this.isDirectClient) {
				return VueEIMZO
			} else {
				return HujjatUzEIMZOClient
			}
		},
		installProjectApiKey() {
			let key = process.env.VUE_APP_EIMZO_KEY

			if (key) {
				let parts = key.split(";")

				if (parts.length >= 2) {
					VueEIMZO.addApiKey(parts[0], parts[1])
				}
			}
		}
	}
}
