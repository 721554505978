import { DOWNLOAD_ENDPOINT, IMAGE_ENDPOINT } from "@Platon/const"
import camelCase from "lodash/camelCase"
import isObject from "lodash/isObject"

export function prettyBytes(num) {
	if (typeof num !== "number" || isNaN(num)) {
		throw new TypeError("Expected a number")
	}

	let exponent
	let unit
	const neg = num < 0
	const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

	if (neg) {
		num = -num
	}

	if (num < 1) {
		return (neg ? "-" : "") + num + " B"
	}

	exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1)
	num = (num / Math.pow(1024, exponent)).toFixed(2) * 1
	unit = units[exponent]

	return (neg ? "-" : "") + num + " " + unit
}

export function formatTime(value) {
	let time = parseInt(value)

	if (Number.isNaN(time)) {
		return "00:00"
	}

	let hours = Math.floor(time / 3600)
	let minutes = Math.floor(time / 60) % 60
	let seconds = time % 60

	let dHours = hours > 9 ? hours : "0" + hours
	let dMins = minutes > 9 ? minutes : "0" + minutes
	let dSecs = seconds > 9 ? seconds : "0" + seconds
	if (time < 3600) {
		return dMins + ":" + dSecs
	} else {
		return dHours + ":" + dMins + ":" + dSecs
	}
}

/**
 * @param {string} url
 */
export function downloadFile(url) {
	window.open(url, "_blank")
}

/**
 * @param {string} id
 */
export function downloadPlatonFile(id) {
	downloadFile(platonFileUrl(id))
}

export function platonFileUrl(id) {
	if (isNullNumeric(id)) return null

	return `${DOWNLOAD_ENDPOINT}/${id}`
}

export function platonImageUrl(id, width = "160", height = "160") {
	if (isNullNumeric(id)) return null

	return `${IMAGE_ENDPOINT}/${id}?scale=w${width}xh${height}`
}

export function stringToColour(str) {
	let hash = 0

	console.log(`Generating code for ${str}`)
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash)
	}
	let colour = "#"
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff
		colour += ("00" + value.toString(16)).substr(-2)
	}
	return colour
}

/**
 * @param {any} value
 * @param {'default'|'boolean'} as
 */
export function parseValue(value, as) {
	if (as === "boolean") {
		if (value === "true") {
			return true
		} else if (value === "false" || value === "null") {
			return false
		}

		return !!value
	}

	if (null === value || undefined === value || "null" === value) {
		return null
	}

	return value
}

export function or(x, or) {
	return isEmpty(x) ? or : x
}

export function ifNull(x, def) {
	return isNullNumeric(x) ? def : x
}

export function isNullNumeric(x) {
	return x === null || x === undefined || Number.isNaN(x)
}

export function isNull(x) {
	return x === null || x === undefined
}

export function isEmpty(x) {
	if (Array.isArray(x)) {
		return x.length === 0
	}

	if (x === null || x === undefined) {
		return true
	}

	return isNullNumeric(x) || x === "" || x === "null"
}

export function isNotEmpty(x) {
	if (Array.isArray(x)) {
		return x.length > 0
	} else if (typeof x === "string") {
		return x.length > 0
	}

	return !!x
}

export function numOr(x, y) {
	let n = Number(x)

	if (n) {
		return n
	}

	return y
}

export function numOrUndefined(x) {
	return numOr(x, undefined)
}

/**
 * @param {EventTarget[]} elements
 * @param {string} tag
 *
 * @return {?EventTarget}
 */
export function getElementInPathByTag(elements, tag) {
	tag = tag.toUpperCase()
	for (let element of elements) {
		if (element.tagName === tag) {
			return element
		}
	}

	return null
}

/**
 * @param {Array} a
 * @param {Array} b
 * @returns {boolean}
 */
function isArrayEqIndexed(a, b) {
	return a.length === b.length && a.every((val, index) => val === b[index])
}

/**
 * @param {Object} obj1
 * @param {Object} obj2
 * @return {boolean}
 */
export function isObjDiff(obj1, obj2) {
	if (!obj1 || !obj2) {
		return false
	}

	let obj1Keys = Object.keys(obj1)
	let obj2Keys = Object.keys(obj2)

	if (obj1Keys.length !== obj2Keys.length) {
		return true
	}

	let theSameKeys = obj1Keys.every((x) => obj2Keys.findIndex((y) => x === y) >= 0)

	if (!theSameKeys) {
		return true
	}

	// the same values
	return obj1Keys.some((key) => {
		const a = obj1[key]
		const b = obj2[key]

		if (Array.isArray(a) && Array.isArray(b)) {
			return !isArrayEqIndexed(a, b)
		}

		return a !== b
	})
}

export function capitalize(word = "") {
	return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

export function generatePassword(length = 12, t) {
	let charset = "!#$%&123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjklmnpqrstuvwxyz"

	if (t === "u") charset = "ABCDEFGHJKLMNPQRSTUVWXYZ"
	else if (t === "l") charset = "abcdefghkmnpqrstuvwxyz"
	else if (t === "a") charset = "ABCDEFGHKMNPQRSTUVWXYZabcdefghkmnpqrstuvwxyz"
	else if (t === "f") charset = "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjklmnpqrstuvwxyz"
	else if (t === "x") charset = "123456789ABCDEFGHKMNPQRSTUVWXYZ"
	else if (t === "y") charset = "123456789abcdefghkmnpqrstuvwxyz"
	else if (t === "n") charset = "123456789"
	else if (t === "p")
		// any printable char (ASCII subset)
		charset = '!"#$%&()*+,-./123456789:;<=>?@ABCDEFGHJKLMNPQRSTUVWXYZ[]^_`abcdefghjklmnpqrstuvwxyz{|}~'
	else if (t === "r")
		// any printable char (ASCII subset) (useful mode)
		charset = "!#$%&()*123456789+/<=>?@{}[]ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjklmnpqrstuvwxyz"
	else if (t === "c")
		// any NON-alphanumeric characters
		charset = '!"#$%&()*+,-./:;<=>?@[]^_`{|}~'
	else if (t === "e")
		// any NON-alphanumeric characters (useful mode)
		charset = "!#$%&()*+/<=>?@{}[]"
	else if (t === "b")
		// any non-alpha characters
		charset = '!"#$%&()*+,-./123456789:;<=>?@[]^_`{|}~'
	else if (t === "d")
		// then -- any non-alpha characters (useful mode)
		charset = "!#$%&()*123456789+/<=>?@{}[]"
	else if (t === "z")
		// recomended for password generation characters
		charset = "-*@#"
	else if (t && t.length > 1) charset = t

	let retVal = ""

	for (let i = 0, n = charset.length; i < length; ++i) {
		retVal += charset.charAt(Math.floor(Math.random() * n))
	}

	return retVal
}

/**
 * @param {object[]} objArray
 * @param {string} param
 */
export function flattenBy(objArray, param) {
	const flat = (/** Array */ items) => {
		return items.reduce((arr, item) => {
			arr.push(item)

			if (Array.isArray(item[param])) arr.push(...flat(item[param]))

			return arr
		}, [])
	}

	return flat(objArray)
}

export function appendSlash(str) {
	if (typeof str === "string" && !str.startsWith("/")) {
		return "/" + str
	}

	return str
}

export function trimSlash(str) {
	if (typeof str === "string" && str.startsWith("/")) {
		return str.substr(1)
	}

	return str
}

export function getTextWidth(text, font = "16px Arial") {
	const element = document.createElement("canvas")
	const context = element.getContext("2d")

	context.font = font
	return context.measureText(text).width
}

/**
 * @param {Object} obj
 */
export function objectToCamelCase(obj) {
	let camelCaseKey

	for (let key of Object.keys(obj)) {
		camelCaseKey = camelCase(key)

		if (isObject(obj[key])) {
			obj[camelCaseKey] = objectToCamelCase(obj[key])
		} else if (camelCaseKey !== key) {
			obj[camelCaseKey] = obj[key]
		}
	}

	return obj
}

/**
 * @callback searchOneByCallback
 * @param {any} item
 */

/**
 * @param {any} root
 * @param {searchOneByCallback} fn
 * @param {string} childKey
 * @return any
 */
export function searchOneBy(root, fn, childKey = "children") {
	if (Array.isArray(root[childKey])) {
		for (let item of root[childKey]) {
			if (fn(item)) {
				return item
			}

			const found = searchOneBy(item, fn, childKey)

			if (found) {
				return found
			}
		}
	} else {
		return null
	}
}

export function cast(field, fieldType, value) {
	if (fieldType === "number" && typeof value !== "number") {
		value = value === "" || value === undefined || value === null ? null : Number(value)

		if (Number.isNaN(value)) {
			console.warn(`Field ${field} got NaN`)
			value = null
		}
	} else if (fieldType === "checkbox" && typeof value !== "boolean") {
		if (typeof value === "string") {
			value = value === "true" || value === "1"
		} else {
			value = !!value
		}
	}

	return value
}

export function downloadBlob(data, name) {
	// const link = document.createElement('a').downloadBlob(data, 'some.xlsx',);
	console.log("downloadBlob end")
	this.href = window.URL.createObjectURL(new Blob([data]))
	this.setAttribute("download", name)
	document.body.appendChild(this)
	this.click()
	console.log("downloadBlob end")
}

export function isMobile() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
