import { mapMutations } from "vuex"
import PluginEvents from "../../core/plugins/PluginEvents"
import SocialLoginModal from "../login/SocialLoginModal.vue"
import { API_BASE_URL } from "@Platon/const"
import DialogWithOTPVerification from "@Platon/components/login/DialogWithOTPVerification.vue"

const LoginProvidersConfig = {
	one_id: {
		codeType: "accessToken"
	},
	linkedin: {
		codeType: "code"
	},
	ssv: {
		codeType: "code"
	}
}

export default {
	data() {
		return {
			loadingProvider: null,
			next: this.$route.query["next"] || process.env.VUE_APP_DEV_HOME_PAGE || "/"
		}
	},

	mounted() {
		this.$on("login-success", this.onLoginSuccess)
	},

	beforeDestroy() {
		this.$off("login-success", this.onLoginSuccess)
	},

	methods: {
		...mapMutations({
			doLogin: "platon/doLogin"
		}),

		openOTPModal(parcel) {
			if (parcel.response.status == 200 && parcel.response.data.error == "Otp password is required") {
				this.$modal.show(
					DialogWithOTPVerification,
					{
						parcel,
						onClose: () => {},
						onSuccess: (response) => {
							this.$emit("login-success", {
								user: response.data.user,
								token: response.data.token
							})
						}
					},
					{
						height: "90%",
						clickToClose: false,
						maxHeight: window.innerHeight * 0.9
					}
				)
			}
		},

		async processLogin({ user, token }) {
			this.doLogin({
				token,
				user
			})

			await this.$plugins.triggerEvent(PluginEvents.AfterLogin, user, token)
			await this.$plugins.triggerEvent(PluginEvents.AfterAuth, user, token)
		},

		async onLoginSuccess({ user, token, parcel }) {
			if (parcel) this.openOTPModal(parcel)

			await this.processLogin({ user, token })

			this.$platonApp.greenToast("Тизимга хуш келибсиз")

			await this.$router.push({ path: this.next })

			this.$plugins.triggerEvent(PluginEvents.SetUserTheme, user)
		},

		loginValidation() {
			const { oauth2 } = this.$projectInfo
			const { code, state } = this.$route.query
			const { codeType } = LoginProvidersConfig[state] || {}
			const redirect_uri = this.validateRedirectUrl(oauth2[state] && oauth2[state].redirect_uri)

			if (code) {
				this.oauthValidateToken({ code, method: state, codeType, redirect_uri, state })
				this.$router.replace({ "query.code": null, "query.state": null })
			}
		},

		/**
		 * @param {HTMLElement} buttonContainer
		 * @param buttonOptions
		 */
		initGoogleLogin(buttonContainer, buttonOptions = {}) {
			if (!(this.$projectInfo.oauth2 && this.$projectInfo.oauth2.google_client_id)) {
				return
			}

			this.addScript("https://accounts.google.com/gsi/client", {
				async: true,
				onload: () => {
					google.accounts.id.initialize({
						client_id: this.$projectInfo.oauth2.google_client_id,
						callback: (response) => {
							this.oauthValidateToken({ code: response.credential, method: "google" })
						}
					})

					google.accounts.id.renderButton(
						buttonContainer,
						Object.assign(
							{
								size: "large",
								width: "390px",
								theme: "outline"
							},
							buttonOptions
						)
					)
				}
			})
		},

		loginWithFacebook() {
			let { oauthValidateToken } = this

			FB.login(
				function (response) {
					if (response.status === "connected") {
						oauthValidateToken({ code: response.authResponse.accessToken, method: "facebook" })
					} else if (response.status === "not_authorized") {
						FB.login(
							function (response) {
								console.log(response)
							},
							{ scope: "public_profile,email" }
						)
					} else {
						console.log("not connected, not logged into facebook, we don't know")
					}
				},
				{ scope: "public_profile,email" }
			)
		},

		loginWithOneId() {
			const { oauth2 } = this.$projectInfo
			const redirectUrl = this.validateRedirectUrl(oauth2.one_id && oauth2.one_id.redirect_url)
			const oneIdUrl = `https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=${oauth2.one_id.client_id}&redirect_uri=${redirectUrl}&scope=${oauth2.one_id.client_id}&state=one_id`

			window.location.replace(oneIdUrl)
		},

		loginWithLinkedin() {
			const { oauth2 } = this.$projectInfo
			const your_client_id = oauth2.linkedin && oauth2.linkedin.client_id
			const redirectUrl = this.validateRedirectUrl(oauth2.linkedin && oauth2.linkedin.redirect_url)

			const linkedIndUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${your_client_id}&redirect_uri=${redirectUrl}&state=linkedin&scope=r_liteprofile,r_emailaddress`

			window.location.replace(linkedIndUrl)
		},

		loginWithSSV() {
			const { ssv } = this.$projectInfo.oauth2
			const { redirect_uri, challenge, authorization_endpoint, client_id } = ssv
			// const client_id = '99261b18-f810-4277-bd46-97a4dc65b2af'
			// const redirect_uri = "http://localhost:8081/login";
			// const authorization_endpoint = 'https://test-sso.ssv.uz/oauth/authorize';
			// const challenge = "6Vy8uAZs_Ry2tf1O6Ct88XFZ06bGxULJEwCSNeXSXSQ"

			const params = new URLSearchParams({
				client_id,
				redirect_uri,
				response_type: "code",
				code_challenge_method: "S256",
				code_challenge: challenge,
				state: "ssv"
			})

			const url = `${authorization_endpoint}?${params.toString()}`
			window.location.replace(url)
		},

		validateRedirectUrl(url) {
			const currentPath = window.location.origin
			if (url && (url.indexOf("http://") === 0 || url.indexOf("https://") === 0)) {
				return url
			} else if (url) {
				return currentPath + url
			} else {
				return currentPath
			}
		},

		async oauthValidateToken({ code, method, codeType = "accessToken", redirect_uri, state }) {
			const body = { [codeType]: code, redirect_uri }
			this.loadingProvider = state
			try {
				const { data } = await this.$api.post("/auth/oauth2-validate", body, {
					baseURL: API_BASE_URL,
					params: { method }
				})

				const accessTokenTypes = ["one_id", "linkedin", "ssv"]

				const token = accessTokenTypes.includes(method) ? data.access_token : code

				const { has_profile, has_password, password_policy } = data

				if (has_profile && password_policy !== "required") {
					await this.oauthLogin({ token, method })
					return
				}

				if (has_password === false && password_policy !== "none") {
					this.showLoginModal({ data, token, method })
				} else {
					await this.oauthLogin({ token, method })
				}
			} catch (error) {
				let message = this.$l("platon.error", "Хатолик")
				if (error.response && error.response.data && error.response.data.message)
					message = error.response.data.message
				this.$emit("login-error", message)
				this.loadingProvider = null
			}
		},

		async oauthLogin({ token, method, password, closeModal }) {
			try {
				const { data } = await this.$api.post(
					"/auth/oauth2",
					{ accessToken: token, password },
					{
						baseURL: API_BASE_URL,
						params: { method }
					}
				)

				this.onLoginSuccess({
					user: data.user,
					token: data.token
				})

				this.loadingProvider = null
				closeModal && closeModal()
			} catch (error) {
				let message = this.$l("platon.error", "Хатолик")
				if (error.response && error.response.data && error.response.data.message)
					message = error.response.data.message
				this.$emit("login-error", message)
				this.loadingProvider = null
			}
		},

		showLoginModal(props) {
			this.$modal.show(
				SocialLoginModal,
				{ ...props, login: this.oauthLogin },
				{ width: "400px", clickToClose: false }
			)
		}
	}
}
