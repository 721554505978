<template>
    <div v-if="hasCustomLoginPage">
        <PageRenderer :chimera-options="loginPageOptions" />
    </div>

    <div v-else>
        <Login />
    </div>
</template>

<script>
import PageRenderer from "@Platon/components/pages/PageRenderer.vue"
import Login from "@Platon/components/login/Login.vue"

export default {
    name: "LoginPage",

    components: { Login, PageRenderer },

    computed: {
        hasCustomLoginPage() {
            return this.$projectInfo.has_login
        },

        loginPageOptions() {
            return {
                url: "public/login"
            }
        }
    }
}
</script>
