<template>
    <div v-if="$isPageBlank()">
        <transition name="fade" mode="out-in">
            <router-view :key="$route.path" />
        </transition>
    </div>

    <platon-layout v-else>
        <transition name="fade" mode="out-in">
            <router-view :key="routeKey" />
        </transition>
    </platon-layout>
</template>

<script>
export default {
    mounted() {
        this.$modal // access to mount modal container to main vue instance; don't remove!
    },

    computed: {
        routeKey() {
            if (this.$route.name === "table" || this.$route.name === "form") {
                return this.$route.fullPath
            } else if (this.$route.name === "page" || this.$route.name === "public-page") {
                return `${this.$route.path}`
            } else {
                return this.$route.name
            }
        }
    }
}
</script>
