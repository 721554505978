<template>
    <div class="login-page pb-4">
        <b-container>
            <b-row>
                <b-col class="mx-auto login-card">
                    <Alert
                        v-if="alertShow"
                        v-model="alertShow"
                        :alert-text="alertText"
                        :alert-dismissible="true"
                        alert-type="danger"
                    />

                    <b-card class="p-4">
                        <div class="font-weight-bold h3 text-center mb-4">{{ $l("platon.appName", $appName) }}</div>
                        <div class="text-gray text-center my-3">{{ $l("platon.loginHeader", "Тизимга кириш") }}</div>

                        <div v-for="(provider, index) in loginProviders" :key="provider.title" class="py-2">
                            <div class="line-text" v-if="provider.divider && index > 0">
                                <span>{{ $l("platon.or", "ёки") }}</span>
                            </div>

                            <component
                                :is="provider.component"
                                @login-success="onLoginSuccess"
                                @login-error="showError"
                            />

                            <div class="line-text" v-if="provider.divider && index + 1 < loginProviders.length">
                                <span>{{ $l("platon.or", "ёки") }}</span>
                            </div>
                        </div>
                    </b-card>

                    <div class="text-center d-flex justify-content-center align-items-center">
                        <LangPicker />
                    </div>
                </b-col>
            </b-row>

            <login-page-contacts />

            <div class="text-center mt-5">
                <div v-if="$const.COPYRIGHT_URL" class="text-center copyright">
                    <a :href="$const.COPYRIGHT_URL" target="_blank" v-html="`© ${$copyright}`"></a>
                </div>
                <div v-else class="text-center copyright" v-html="`© ${$copyright}`"></div>
            </div>
        </b-container>
    </div>
</template>

<script>
import { mapMutations } from "vuex"
import { ENABLED_LOGIN_PROVIDERS } from "@Platon/const"
import LoginMixin from "@Platon/components/login/LoginMixin"
import Alert from "@Platon/components/extended/Alert.vue"
import LangPicker from "@Platon/components/misc/LangPicker.vue"
import { providers } from "./Providers"
import DialogWithOTPVerification from "@Platon/components/login/DialogWithOTPVerification.vue"

export default {
    name: "Login",
    components: { LangPicker, Alert, DialogWithOTPVerification },

    mixins: [LoginMixin],

    data() {
        return {
            alertText: "",
            alertShow: false
        }
    },

    created() {
        localStorage.removeItem("login_eimzo_key")
        localStorage.removeItem("user_organizations")
    },

    methods: {
        ...mapMutations({
            doLogin: "platon/doLogin"
        }),

        showError(text) {
            this.alertText = text
            this.alertShow = !!text
        },

        getProvider(provider) {
            return providers[provider] ?? null
        }
    },

    computed: {
        loginProviders() {
            return ENABLED_LOGIN_PROVIDERS.map((provider) => this.getProvider(provider))
        },

        hasMultipleProviders() {
            return this.loginProviders.length > 1
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/vars";

.login-page {
    background: var(--pl-login-page-bg-color);
    min-height: 100vh;
}

.login-card {
    width: 500px;
    max-width: 500px;
    margin-top: 12vh;
}

.text-gray {
    color: #9a9a9a;
}

.copyright {
    color: darken($gray-2, 30%);
}

.line-text {
    padding: 12px 0;
    position: relative;
    text-align: center;
}

.line-text:before {
    content: "";
    display: block;
    border-top: solid 1px #dcdcdc;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    z-index: 0;
}

.line-text span {
    color: var(--pl-login-page-line-text-color);
    background: var(--pl-card-bg);
    padding: 0 16px;
    position: relative;
    z-index: 1;
}
</style>
