<template>
    <div>
        <b-alert
            v-model="show"
            @input="$emit('input', $event)"
            :variant="alertType"
            :dismissible="alertDismissible"
            fade
        >
            <slot></slot>
            <span v-html="alertText"></span>
        </b-alert>
    </div>
</template>

<script>
export default {
    name: "Alert",
    props: {
        value: false,
        alertText: "",
        alertType: "",
        alertDismissible: false
    },
    data() {
        return {
            show: this.value,
            dismissSecs: 5,
            dismissCountDown: 0
        }
    },
    computed: {},
    mounted() {},

    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        }
    }
}
</script>
