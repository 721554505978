<template>
    <component v-if="tag === 'span'" :is="tag">
        <slot></slot>
    </component>

    <component
        v-else-if="tag === 'a'"
        :is="tag"
        v-bind="extra"
        ref="link"
        :class="{ link: isValidLink }"
        @click="onFormClick"
    >
        <slot></slot>
    </component>

    <component v-else :is="tag" v-bind="extra" ref="link" :class="{ link: isValidLink }" @click.native="onFormClick">
        <slot></slot>
    </component>
</template>

<script>
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import { mapMutations } from "vuex"

export default {
    name: "PlatonLink",

    mixins: [NavigationMixin],

    props: {
        link: {},

        linkParams: {},

        stopPropagation: {}
    },

    mounted() {
        // if (this.extra && this.extra.to) {
        // 	this.$refs.link.$el.addEventListener('click', this.onFormClick)
        // }
        // if (!this.isValidLink) {
        // 	this.replaceDefaultLinkWithRouter(this.$refs.link, this.linkParams)
        // }
    },

    methods: {
        /**
         * @param {MouseEvent} e
         */
        ...mapMutations({
            collapseMenu: "platon/toggleMenuCollapse"
        }),
        onMenuLinkCollapse() {
            if (window.innerWidth <= 1200) this.collapseMenu()
        },

        onFormClick(e) {
            e.preventDefault()

            if (!this.isValidLink) {
                return
            }

            if (this.isValidLink) {
                this.onMenuLinkCollapse()
            }

            if (this.stopPropagation === true) e.stopPropagation()

            if (this.routeInfo.type === "internal") {
                let route = this.routeInfo.route

                // on mac command pressed or ctrl pressed
                let isMac = navigator.platform.toLowerCase().includes("mac")
                let forceOpenInNewWindow = (isMac && e.metaKey) || (!isMac && e.ctrlKey)

                if (forceOpenInNewWindow) {
                    let openInNewTabRoute = this.resolveNavigateTo(this.link, this.linkParams || {})

                    openInNewTabRoute.route.query._target = "blank"

                    if (openInNewTabRoute && openInNewTabRoute.route) {
                        route = this.$router.resolve(openInNewTabRoute.route).route
                    }
                }

                this.navigateToRoute(route)
            } else {
                let { target, link } = this.routeInfo

                if (target === "blank") window.open(link, "_blank")
                else window.open(link, "_self")
            }
        }
    },

    computed: {
        routeInfo() {
            return this.resolveNavigateTo(this.link, this.linkParams || {})
        },

        extra() {
            if (!this.isValidLink) {
                return {}
            }

            let extra = {}
            let route = this.routeInfo

            if (route.type === "external") {
                extra.href = route.link
            } else {
                extra.to = route.link
                extra.event = ""
            }

            if (route.target) {
                extra.target = route.target
            }

            return extra
        },

        isValidLink() {
            return this.link !== "#" && this.routeInfo.link && this.routeInfo.type
        },

        tag() {
            if ((this.routeInfo.type === "external" && this.isValidLink) || this.link === "#") {
                return "a"
            } else if (this.routeInfo.type === "internal" && this.isValidLink) {
                return "router-link"
            } else {
                return "span"
            }
        }
    }
}
</script>
