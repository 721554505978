import { API_BASE_URL } from "@Platon/const"
import { $api } from "@Platon/core/core"
import PluginEvents from "@Platon/core/plugins/PluginEvents"

export const actions = {
	/**
	 * @param {ActionContext} context
	 * @param {{orgId: string}} payload
	 */
	async switchOrganization(context, payload) {
		try {
			let response = await $api({
				baseURL: API_BASE_URL,
				url: "auth/change_user_org",
				params: {
					org_id: payload.orgId
				}
			})

			// update current user's orgId
			context.commit("setUserParam", { key: "orgId", value: payload.orgId })
		} catch (e) {
			console.log("Error while changing org", e)
		}
	},

	/**
	 *
	 * @param context
	 * @param payload
	 * @param {function} payload.afterLogout
	 * @param {string} payload.redirectUrl
	 * @returns {Promise<void>}
	 */
	async logout(context, payload) {
		try {
			await PlatonPluginCore.triggerEvent(PluginEvents.AfterLogout, context.state.user)
		} catch (e) {
			console.error(e)
		} finally {
			context.commit("doLogout")

			setTimeout(async () => {
				if (typeof payload.afterLogout === "function") {
					await payload.afterLogout()
				}

				location.href = payload.redirectUrl
			}, 50)
		}
	}
}
