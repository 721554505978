import PlatonPlugin from "@Platon/core/plugins/PlatonPlugin"

export default class PlatonSentryPlugin extends PlatonPlugin {
	load(core) {
		super.load(core)

		if (process.env.VUE_APP_ENABLE_SENTRY) {
			this.loadSentry()
		}
	}

	loadSentry() {
		if (process.env.NODE_ENV == "production") {
			const { Integrations } = require("@sentry/tracing")
			const Sentry = require("@sentry/vue")

			Sentry.init({
				url: process.env.SENTRY_URL || "https://sentry.platon.uz",
				dsn: process.env.VUE_APP_SENTRY_DNS,
				release: require("../../../package.json").version,
				environment: process.env.NODE_ENV,

				integrations: [new Integrations.BrowserTracing()],
				Vue: window.PlatonVue,
				tracesSampleRate: 0.2
			})
		}
	}
}
