<template>
    <div class="text-center mt-4">
        <div class="text-gray d-flex flex-row justify-content-center align-center" style="gap: 40px">
            <div class="d-flex flex-column align-items-start">
                <a :href="CONTACT_TELEGRAM" target="_blank" class="d-flex align-items-center">
                    <i style="font-size: 20px" class="fab fa-telegram-plane mr-2"></i> {{ CONTACT_PHONE }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { CONTACT_PHONE, CONTACT_TELEGRAM } from "@Platon/const"
export default {
    name: "LoginPageContacts",
    data() {
        return {
            CONTACT_PHONE,
            CONTACT_TELEGRAM
        }
    }
}
</script>
